<template>
  <div></div>
</template>

<script>
export default {
  name: "Global"
};
</script>

<style>
/* RESPONSIVE MOBILE*/
@media screen and (max-width: 377px) {
  .kategori-mobile .img-wrapper-mobile p {
    font-size: 20px !important;
  }

  .jam-wrapper,
  .hari-wrapper,
  .bulan-wrapper {
    padding: 2px 7px;
    border-radius: 3px;
  }

  .jam-wrapper p {
    font-weight: 600;
    font-size: 12px !important;
  }
  .kategori-sewa-list {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
  }
  .cardz {
    /* width: 196px; */
  }

  .harga-diskon {
    margin-top: 10px;
    font-size: 12px !important;
  }

  .harga-asli {
    font-weight: bold;
    font-size: 18px !important;
  }

  .harga-asli span {
    font-size: 12px !important;
  }

  .nama-produk {
    font-size: 18px !important;
  }

  .sisa-barang {
    margin-top: 12px;
    font-size: 13px !important;
  }
}

.mobile {
  display: none;
}

@media screen and (max-width: 1399px) {
  .container {
    max-width: 1320px !important;
  }
}

@media screen and (max-width: 500px) {
  .desktop {
    display: none;
  }

  .container {
    max-width: 470px;
  }

  .mobile {
    display: block !important;
  }
}

/* UTILITIES */
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
}
.disNone {
  display: none !important;
}
.disBlock {
  display: block !important;
}
section {
  font-family: "Nunito Sans";
}

a {
  text-decoration: none !important;
}

.text-hover:hover {
  color: #335aff !important;
}

footer ul li a:hover {
  color: #335aff !important;
}

::placeholder {
  font-size: 15px;
  color: #bbbbbb;
}

.lala {
  display: block !important;
  transition: 0.3s;
  transform: translateX(0) !important;
}

.lalanone {
  display: none;
  transition: 0.3s;
  transform: translateX(20px);
}

.head-produk {
  margin-bottom: 30px;
}

.head-produk a {
  font-weight: 600;
  font-size: 16px;

  color: #3c3c3c;
}

.head-produk h2 {
  font-weight: bold;
  font-size: 30px;

  color: #3c3c3c;
}

.arrowLeftWrappers {
  margin-right: 15px;
  background: white;
  border: solid 1px #335aff;
  width: 45px;
  height: 45px;
  border-radius: 8px;
  line-height: 0px;
  text-align: center;
  margin-left: 60px;
  transition: 0.3s;
}

.arrowLeftWrappers:hover {
  transition: 0.3s;
  background: #335aff80;
}

.arrowRightWrappers:hover {
  transition: 0.3s;
  background: #335aff80;
}

.arrowRightWrappers {
  border: solid 1px #335aff;
  width: 45px;
  height: 45px;
  background: #335aff;
  border-radius: 8px;
  line-height: 0px;
  text-align: center;
  transition: 0.3s;
}

.line {
  height: 1px;
  background: #e0e0e0;
  margin: 40px 0;
  width: 100%;
}

.button-primary {
  background: #335aff;
  padding: 10px 34px;
  color: white;
  border-radius: 5px;
  border: solid 1px #335aff;
  transition: 0.3s;
}

.button-primary:hover {
  background: #335aff50;
  transition: 0.3s;
  padding: 10px 34px;
  color: white;
  border-radius: 5px;
}

.color-white {
  color: white;
}

ul li {
  list-style: none;
}
</style>