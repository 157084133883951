<template>
  <div>
    <footer>
      <Global />
      <div class="container">
        <div class="row">
          <div class="col-lg-2">
            <div class="logo-wrapper">
              <a href>
                <img class="img-fluid" src="../assets/svg/logo.svg" alt />
              </a>
              <p style="lineHeight: 22px;">#1 Rental barang seluruh daerah Barlingmascakeb</p>
            </div>
            <div class="d-flex sosial-wrapper">
              <img src="../assets/mobile/svg/sosial1.svg" alt />
              <img src="../assets/mobile/svg/sosial2.svg" alt />
              <img src="../assets/mobile/svg/sosial3.svg" alt />
              <img src="../assets/mobile/svg/sosial4.svg" alt />
              <img src="../assets/mobile/svg/sosial5.svg" alt />
            </div>
          </div>
          <div class="col-lg-2">
            <ul class="carter">
              <li>
                <h5 class="mb-0">Carter.</h5>
              </li>
              <li>
                <a href="#">Tentang Carter.</a>
              </li>
              <li>
                <a href="#">Aturan Penggunaan</a>
              </li>
              <li>
                <a href="#">Kebijakan Privasi</a>
              </li>
              <li>
                <a href="#">FAQ (Tanya Jawab)</a>
              </li>
            </ul>
          </div>
          <div class="col-lg-2">
            <ul class="layanan">
              <li>
                <h5 class="mb-0">Layanan Kami</h5>
              </li>
              <li>
                <a href="#">Purwokerto</a>
              </li>
              <li>
                <a href="#">Purbalingga</a>
              </li>
              <li>
                <a href="#">Cilacap</a>
              </li>
              <li>
                <a href="#">Banjarnegara</a>
              </li>
              <li>
                <a href="#">Kebumen</a>
              </li>
              <li>
                <a href="#">Pemalang</a>
              </li>
              <li>
                <a href="#">Wonosobo</a>
              </li>
            </ul>
          </div>
          <div class="col-lg-2">
            <ul class="pembeli">
              <li>
                <h5 class="mb-0">Pembeli</h5>
              </li>
              <li>
                <a href="#">Cara Cari Barang</a>
              </li>
              <li>
                <a href="#">Pembayaran</a>
              </li>
              <li>
                <a href="#">Jaminan Aman</a>
              </li>
            </ul>
            <ul class="pembeli mt-4">
              <li>
                <h5 class="mb-0">Penyedia</h5>
              </li>
              <li>
                <a href="#">Cara Berjualan</a>
              </li>
              <li>
                <a href="#">Keuntungan Penyedia</a>
              </li>
              <li>
                <a href="#">Indeks Merek</a>
              </li>
            </ul>
          </div>
          <div class="col-lg-2">
            <ul class="bantuan">
              <li>
                <h5 class="mb-0">Bantuan</h5>
              </li>
              <li>
                <a href="#">Syarat dan Ketentuan</a>
              </li>
              <li>
                <a href="#">Kebijakan Privacy</a>
              </li>
              <li>
                <a href="#">Komplain Pesanan</a>
              </li>
              <li>
                <a href="#">Hubungi Kami</a>
              </li>
            </ul>
          </div>
          <div class="col-lg-2">
            <ul class="starz">
              <li>
                <img src="../assets/img/star-img.png" alt />
              </li>
              <li>
                <p>
                  2.300 ribu orang memiliki pengalaman yang baik
                  <br />bersama kami!
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
    <section class="cp">
      <div class="container">
        <div class="row">
          <p class="mb-0">2021 © Carter. - All right reserved.</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Global from "../screens/Global";

export default {
  name: "footer",
  components: {
    Global
  }
};
</script>

<style scoped>
footer .sosial-wrapper {
  margin-top: 8px;
}
footer .sosial-wrapper img {
  margin-right: 5px;
}

footer .starz {
  margin-left: -30px;
}

footer .layanan {
  margin-left: 30px;
}

footer .carter {
  margin-left: 20px;
}

footer .bantuan {
  margin-left: -15px;
}

footer .pembeli {
  margin-left: 10px;
}

footer ul li {
  margin-bottom: 10px;
}

footer ul li a,
footer p {
  font-size: 15px;
  color: #787878;
}

footer .logo-wrapper img {
  margin-bottom: 15px;
  margin-top: -5px;
}

.cp p {
  padding-bottom: 40px;
  margin-top: 106px;
  font-size: 14px;
  color: #3c3c3c;
}
</style>