<template>
  <div class="cardz">
    <div class="keranjang-wislist-wrapper">
      <div class="keranjang-wishlist">
        <img src="../assets/svg/love.svg" alt />
      </div>
      <div class="keranjang-wishlist mt-2">
        <img src="../assets/svg/keranjang.svg" alt />
      </div>
    </div>
    <img class="img-fluid p-1" width="100%" :src="image" alt />
    <div class="cardz-body">
      <div class="kategori-sewa-list d-flex">
        <div :class="{ disBlock : perJam === 1  }" class="disNone jam-wrapper">
          <p class="mb-0">/jam</p>
        </div>
        <div :class="{ disBlock : perHari === 1  }" class="disNone hari-wrapper">
          <p class="mb-0">/hari</p>
        </div>
        <div :class="{ disBlock : perBulan === 1  }" class="disNone bulan-wrapper">
          <p class="mb-0">/bulan</p>
        </div>
      </div>
      <h5 class="mb-0 nama-produk">{{ namaProduk }}</h5>
      <p class="mb-0 harga-diskon">{{discount}}</p>
      <h5 class="mb-0 harga-asli">
        {{price}}
        <span>{{timeType}}</span>
      </h5>
      <p class="mb-0 sisa-barang">Sisa Barang : {{stock}}</p>
      <div class="progress custom-progress-bar">
        <div
          class="progress-bar w-75"
          role="progressbar"
          aria-valuenow="75"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
// import { Carousel, Slide } from "vue-carousel";

export default {
  name: "listProduk",
  props: [
    "namaProduk",
    "title",
    "image",
    "discount",
    "price",
    "timeType",
    "stock",
    "perJam",
    "perHari",
    "perBulan"
  ]
  // comppnents: {
  //   Carousel,
  //   Slide
  // }
};
</script>

<style scoped>
.jam-wrapper,
.hari-wrapper,
.bulan-wrapper {
  padding: 2px 7px;
  border-radius: 3px;
}

.jam-wrapper {
  background: #dfe5ff;
  margin-right: 5px;
  margin-bottom: 6px;
}

.hari-wrapper {
  background: #d6ffd7;
  margin-right: 5px;
  margin-bottom: 6px;
}
.bulan-wrapper {
  background: #ffe4e4;
  margin-right: 5px;
  margin-bottom: 6px;
}
.jam-wrapper p {
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 0 !important;
  color: #335aff;
}
.hari-wrapper p {
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 0 !important;
  color: #37be3d;
}
.bulan-wrapper p {
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 0 !important;
  color: #e06464;
}

.head-produk {
  margin-bottom: 30px;
}

.head-produk a {
  font-weight: 600;
  font-size: 16px;

  color: #3c3c3c;
}

.head-produk h2 {
  font-weight: bold;
  font-size: 30px;

  color: #3c3c3c;
}

.arrowLeftWrapper {
  margin-right: 15px;
  background: white;
  border: solid 1px #335aff;
  width: 45px;
  height: 45px;
  border-radius: 8px;
  line-height: 40px;
  text-align: center;
  margin-left: 60px;
}
.arrowRightWrapper {
  border: solid 1px #335aff;
  width: 45px;
  height: 45px;
  background: #335aff;
  border-radius: 8px;
  line-height: 40px;
  text-align: center;
}

.keranjang-wishlist {
  padding: 8px;
  width: 37px;
  height: 37px;
  border-radius: 5px;
  background: #fff;
}

.keranjang-wislist-wrapper {
  position: absolute;
  right: 18px;
  top: 18px;
  flex-direction: column;
  display: flex;
  opacity: 0;
  transform: translateX(40px);
  transition: 0.3s;
}

.cardz:hover .keranjang-wislist-wrapper {
  opacity: 1;
  transition: 0.3s;
  transform: translateX(0px);
}

.keranjang-wislist-wrapper img {
  display: flex;
  justify-content: center;
  width: 21px;
  height: 21px;
}

.custom-progress-bar {
  height: 3px !important;
  margin-top: 7px;
  margin-bottom: 3px;
}

.sisa-barang {
  margin-top: 12px;
  font-size: 15px;
  line-height: 20px;
  color: #010223;
}

.cardz {
  width: 196px;
  cursor: pointer;
  background: white;
  position: relative;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  transition: 0.3s;
}

.cardz:hover {
  transform: translateY(-10px);
  transition: 0.3s;
  box-shadow: 0px 12px 30px 0px rgba(164, 163, 163, 0.2);
}

.harga-diskon {
  margin-top: 10px;
  font-size: 15px;
  text-decoration-line: line-through;
  color: #8d9194;
}

.harga-asli {
  font-weight: bold;
  font-size: 23px;
  color: #335aff;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}

.harga-asli span {
  font-weight: normal;
  font-size: 15px;
  color: #8d9194;
}

.nama-produk {
  white-space: nowrap;
  color: #3c3c3c;
  font-size: 20px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.cardz-body {
  padding: 15px 15px 15px 20px;
}

.kategori-sewa {
  font-size: 15px;
  font-weight: norfal;
  color: #8d9194;
}
.kategori-sewa span {
  font-weight: 600;
  color: #3c3c3c;
}
</style>