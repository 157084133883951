<template>
  <div>
    <section class="mobile">
      <!-- NAV -->
      <Global />
      <div class="nav-mobile">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="d-flex justify-content-between">
                <img src="../../assets/mobile/svg/logo-icon.svg" alt />
                <div>
                  <img style="margin-left:17px;" src="../../assets/mobile/svg/love-icon.svg" alt />
                  <img
                    style="margin-left:17px;"
                    src="../../assets/mobile/svg/keranjang-icon.svg"
                    alt
                  />
                  <img style="margin-left:17px;" src="../../assets/mobile/svg/account-icon.svg" alt />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="puk sticky">
        <div class="container">
          <div class="row">
            <div class="col">
              <div>
                <div class="input-wrapper-mobile d-flex align-items-center">
                  <img src="../../assets/svg/filter.svg" alt />
                  <input placeholder="Cari Barang Anda" type="text" />
                  <div class="search-wrapper-mobile">
                    <img src="../../assets/mobile/svg/search-icon.svg" alt />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- HEADER -->
      <div class="header-mobile">
        <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src="../../assets/mobile/img/header-img.png" class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item">
              <img src="../../assets/mobile/img/header-img2.png" class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item">
              <img src="../../assets/mobile/img/header-img3.png" class="d-block w-100" alt="..." />
            </div>
          </div>
        </div>
      </div>

      <!-- KATEGORI -->
      <div class="kategori-mobile mt-4">
        <div class="container">
          <div class="row mb-4">
            <div class="col-6 text-center">
              <div class="img-wrapper-mobile">
                <img
                  class="img-fluid"
                  src="https://res.cloudinary.com/sarjanalidi/image/upload/v1629902329/carter/category/image_63_xbpcdi.png"
                  alt="kategori-barang"
                />
                <p class="mb-0">Teknologi</p>
              </div>
            </div>
            <div class="col-6 text-center">
              <div class="img-wrapper-mobile">
                <img
                  class="img-fluid"
                  src="https://res.cloudinary.com/sarjanalidi/image/upload/v1629902328/carter/category/image_65_eueze6.png"
                  alt="kategori-barang"
                />
                <p class="mb-0">Alat Rumah Tangga</p>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-6 text-center">
              <div class="img-wrapper-mobile">
                <img
                  class="img-fluid"
                  src="https://res.cloudinary.com/sarjanalidi/image/upload/v1629902327/carter/category/image_70_sqhiam.png"
                  alt="kategori-barang"
                />
                <p class="mb-0">Perlengkapan Bayi</p>
              </div>
            </div>
            <div class="col-6 text-center">
              <div class="img-wrapper-mobile">
                <img
                  class="img-fluid"
                  src="https://res.cloudinary.com/sarjanalidi/image/upload/v1629902461/carter/category/image_68_dxxdcc.png"
                  alt="kategori-barang"
                />
                <p class="mb-0">Alat Olahraga</p>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-6 text-center">
              <div class="img-wrapper-mobile">
                <img
                  class="img-fluid"
                  src="https://res.cloudinary.com/sarjanalidi/image/upload/v1629902327/carter/category/image_69_njrlqv.png"
                  alt="kategori-barang"
                />
                <p class="mb-0">Fashion</p>
              </div>
            </div>
            <div class="col-6 text-center">
              <div class="img-wrapper-mobile">
                <img
                  class="img-fluid"
                  src="../../assets/img/kategori-img-6.png"
                  alt="kategori-barang"
                />
                <p class="mb-0">Alat Musik</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- SEDANG PROMO -->
      <div class="sedang-promo-mobile">
        <div class="container">
          <div class="row head-produk">
            <div class="col-lg-6">
              <div class="d-flex align-items-center justify-content-between">
                <h2 class="mb-0">Sedang Promo</h2>
                <a style="margin-right:25px;" class="mb-0" href="#">Lihat Semua</a>
              </div>
            </div>
          </div>
          <div class="row">
            <carousel
              :dots="false"
              :loop="true"
              :responsive="{0:{items:1.8,nav:false},600:{items:2},850:{items:3},1199:{items:5}}"
            >
              <div
                style="marginRight: 8px"
                :class="{ lala : myPromo.id > first && myPromo.id < second }"
                class="col-lg-2"
                v-for="myPromo in promo"
                :key="myPromo.id"
              >
                <ListProduk
                  :namaProduk="myPromo.nama"
                  :image="myPromo.image"
                  :discount="myPromo.discount"
                  :price="myPromo.price"
                  :timeType="myPromo.timeType"
                  :stock="myPromo.stock"
                  :perJam="myPromo.perJam"
                  :perHari="myPromo.perHari"
                  :perBulan="myPromo.perBulan"
                />
              </div>
            </carousel>
          </div>
        </div>
      </div>

      <!-- REKOMENDASI -->
      <div class="rekomendasi-mobile mt-4">
        <div class="container">
          <div class="row head-produk">
            <div class="col-lg-6">
              <div class="d-flex align-items-center justify-content-between">
                <h2 class="mb-0">Rekomendasi</h2>
                <a style="margin-right:25px;" class="mb-0" href="#">Lihat Semua</a>
              </div>
            </div>
          </div>
          <div class="row">
            <carousel
              :nav="false"
              :dots="false"
              :loop="true"
              :autoplayHoverPause="true"
              :responsive="{0:{items:1.8,nav:false},600:{items:2},850:{items:3},1199:{items:5}}"
            >
              <div
                style="marginRight: 8px"
                :class="{ lala : myRekomendasi.id > first && myRekomendasi.id < second }"
                class="col-lg-2"
                v-for="myRekomendasi in rekomendasi"
                :key="myRekomendasi.id"
              >
                <ListProduk
                  :namaProduk="myRekomendasi.nama"
                  :image="myRekomendasi.image"
                  :discount="myRekomendasi.discount"
                  :price="myRekomendasi.price"
                  :timeType="myRekomendasi.timeType"
                  :stock="myRekomendasi.stock"
                  :perJam="myRekomendasi.perJam"
                  :perHari="myRekomendasi.perHari"
                  :perBulan="myRekomendasi.perBulan"
                />
              </div>
            </carousel>
          </div>
        </div>
      </div>
      <!-- BARANG TERLARIS -->
      <div class="terlaris-mobile mt-4">
        <div class="container">
          <div class="row head-produk">
            <div class="col-lg-6">
              <div class="d-flex align-items-center justify-content-between">
                <h2 class="mb-0">Barang Terlaris</h2>
                <a style="margin-right:25px;" class="mb-0" href="#">Lihat Semua</a>
              </div>
            </div>
          </div>
          <div class="row">
            <carousel
              :nav="false"
              :dots="false"
              :loop="true"
              :autoplayHoverPause="true"
              :responsive="{0:{items:1.8,nav:false},600:{items:2},850:{items:3},1199:{items:5}}"
            >
              <div
                style="marginRight: 8px"
                :class="{ lala : myTerlaris.id > first && myTerlaris.id < second }"
                class="col-lg-2"
                v-for="myTerlaris in terlaris"
                :key="myTerlaris.id"
              >
                <ListProduk
                  :namaProduk="myTerlaris.nama"
                  :image="myTerlaris.image"
                  :discount="myTerlaris.discount"
                  :price="myTerlaris.price"
                  :timeType="myTerlaris.timeType"
                  :stock="myTerlaris.stock"
                  :perJam="myTerlaris.perJam"
                  :perHari="myTerlaris.perHari"
                  :perBulan="myTerlaris.perBulan"
                />
              </div>
            </carousel>
          </div>
        </div>
      </div>

      <!-- QUESTION -->
      <div class="question-mobile">
        <div class="container">
          <div class="row">
            <div class="col">
              <div>
                <h1 class="mb-0">
                  Anda sedang mencari
                  <br />barang atau ingin
                  <br />Menyewakan barang?
                </h1>
                <div class="cari-barang">
                  <button>Cari Barang?</button>
                </div>
                <div class="sewa-barang">
                  <button>Sewakan Barang</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- FOOTER -->
      <div class="footer-mobile mt-4">
        <div class="container">
          <div class="row">
            <div class="col-6 logo-wrapper">
              <img src="../../assets/mobile/svg/logo-footer.svg" alt />
              <h5 class="mb-0">#1 Rental barang baru dan bekas daerah Barlingmascakeb.</h5>
              <div class="d-flex sosial-wrapper">
                <img src="../../assets/mobile/svg/sosial1.svg" alt />
                <img src="../../assets/mobile/svg/sosial2.svg" alt />
                <img src="../../assets/mobile/svg/sosial3.svg" alt />
                <img src="../../assets/mobile/svg/sosial4.svg" alt />
                <img src="../../assets/mobile/svg/sosial5.svg" alt />
              </div>
            </div>
            <div class="col-6 carter">
              <ul>
                <li>
                  <p class="mb-0">Carter</p>
                </li>
                <li>
                  <a href="#">Tentang Carter.</a>
                </li>
                <li>
                  <a href="#">Aturan Penggunaan</a>
                </li>
                <li>
                  <a href="#">Kebijakan Privasi</a>
                </li>
                <li>
                  <a href="#">FAQ (Tanya Jawab)</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-6 layanan">
              <ul>
                <li>
                  <p class="mb-0">Layanan Kami</p>
                </li>
                <li>
                  <a href="#">Purwokerto</a>
                </li>
                <li>
                  <a href="#">Purbalingga</a>
                </li>
                <li>
                  <a href="#">Cilacap</a>
                </li>
                <li>
                  <a href="#">Banjarnegara</a>
                </li>
                <li>
                  <a href="#">Kebumen</a>
                </li>
                <li>
                  <a href="#">Pemalang</a>
                </li>
                <li>
                  <a href="#">Wonosobo</a>
                </li>
              </ul>
            </div>
            <div class="col-6 pembeli">
              <ul>
                <li>
                  <p class="mb-0">Pembeli</p>
                </li>
                <li>
                  <a href="#">Cara Cari Barang</a>
                </li>
                <li>
                  <a href="#">Pembayaran</a>
                </li>
                <li>
                  <a href="#">Jaminan Aman</a>
                </li>
              </ul>
              <ul>
                <li>
                  <p class="mb-0">Penyedia</p>
                </li>
                <li>
                  <a href="#">Cara Berjualan</a>
                </li>
                <li>
                  <a href="#">Keuntungan Penyedia</a>
                </li>
                <li>
                  <a href="#">Indeks Merek</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-6 bantuan">
              <ul>
                <li>
                  <p class="mb-0">Bantuan</p>
                </li>
                <li>
                  <a href="#">Syarat dan Ketentuan</a>
                </li>
                <li>
                  <a href="#">Kebijakan Privacy</a>
                </li>
                <li>
                  <a href="#">Komplain Pesanan</a>
                </li>
                <li>
                  <a href="#">Hubungi Kami</a>
                </li>
                <li>
                  <a href="#">Paduan Keamanan</a>
                </li>
              </ul>
            </div>
            <div class="col-6 pembeli">
              <ul>
                <li>
                  <p class="mb-0">Pembeli</p>
                </li>
                <li>
                  <a href="#">Cara Cari Barang</a>
                </li>
                <li>
                  <a href="#">Pembayaran</a>
                </li>
                <li>
                  <a href="#">Jaminan Aman</a>
                </li>
              </ul>
              <ul>
                <li>
                  <p class="mb-0">Penyedia</p>
                </li>
                <li>
                  <a href="#">Cara Berjualan</a>
                </li>
                <li>
                  <a href="#">Keuntungan Penyedia</a>
                </li>
                <li>
                  <a href="#">Indeks Merek</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="cpyr">
        <div class="container">
          <p>2021 © Carter. - All right reserved.</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Global from "../../screens/Global";
import carousel from "vue-owl-carousel";
import ListProduk from "../../components/ListProduk";

export default {
  name: "berandaMobile",
  data: function() {
    return {
      firstPromo: 0,
      secondPromo: 6,
      firstRekomendasi: 0,
      secondRekomendasi: 7,
      first: 0,
      second: 7,
      slides: [
        {
          title: "I am Slide A",
          id: 1
        },
        {
          title: "I am Slide B",
          id: 2
        },
        {
          title: "I am Slide C",
          id: 3
        },
        {
          title: "I am Slide D",
          id: 4
        },
        {
          title: "I am Slide E",
          id: 5
        }
      ],
      promo: [
        {
          id: 1,
          nama: "Gitar Akustik",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629902732/carter/product/image_72_ywku0y.png",
          discount: "Rp 24.000/jam",
          price: "Rp 7.000",
          timeType: "/jam",
          stock: 18,
          perJam: 0,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 2,
          nama: "Kursi Hitam Modern",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959764/carter/product/image_33_clha5g.png",
          discount: "Rp 7.000/jam",
          price: "Rp 7.000",
          timeType: "/jam",
          stock: 30,
          perJam: 1,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 3,
          nama: "Bola Warna",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959765/carter/product/image_85_kydbvc.png",
          discount: "Rp 54.000/jam",
          price: "Rp 3.000",
          timeType: "/jam",
          stock: 120,
          perJam: 1,
          perHari: 0,
          perBulan: 1
        },
        {
          id: 4,
          nama: "Jas Merah + Dasi",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959761/carter/product/image_74_vxbx4t.png",
          discount: "Rp 40.000/jam",
          price: "Rp 25.000",
          timeType: "/jam",
          stock: 22,
          perJam: 1,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 5,
          nama: "Drone Modern",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959761/carter/product/image_71_tfohbx.png",
          discount: "Rp 110.000/jam",
          price: "Rp79.000",
          timeType: "/jam",
          stock: 53,
          perJam: 1,
          perHari: 0,
          perBulan: 1
        },
        {
          id: 6,
          nama: "Gitar Akustik",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629902732/carter/product/image_72_ywku0y.png",
          discount: "Rp 24.000/jam",
          price: "Rp 7.000",
          timeType: "/jam",
          stock: 18,
          perJam: 0,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 7,
          nama: "Kursi Hitam Modern",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959764/carter/product/image_33_clha5g.png",
          discount: "Rp 7.000/jam",
          price: "Rp 7.000",
          timeType: "/jam",
          stock: 30,
          perJam: 1,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 8,
          nama: "Bola Warna",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959765/carter/product/image_85_kydbvc.png",
          discount: "Rp 54.000/jam",
          price: "Rp 3.000",
          timeType: "/jam",
          stock: 120,
          perJam: 1,
          perHari: 0,
          perBulan: 1
        },
        {
          id: 9,
          nama: "Jas Merah + Dasi",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959761/carter/product/image_74_vxbx4t.png",
          discount: "Rp 40.000/jam",
          price: "Rp 25.000",
          timeType: "/jam",
          stock: 22,
          perJam: 1,
          perHari: 1,
          perBulan: 1
        }
      ],
      rekomendasi: [
        {
          id: 1,
          nama: "Kamera Analog",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959757/carter/product/image_79_czi7xw.png",
          discount: "Rp 68.000/hari",
          price: "Rp 55.000",
          timeType: "/hari",
          stock: 18,
          perJam: 0,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 2,
          nama: "Sempoa Berwarna",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959757/carter/product/image_79_czi7xw.png",
          discount: "Rp 9.500/jam",
          price: "Rp 5.000",
          timeType: "/jam",
          stock: 67,
          perJam: 1,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 3,
          nama: "Drone Modern",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959761/carter/product/image_71_tfohbx.png",
          discount: "Rp 210.000/jam",
          price: "Rp 189.000",
          timeType: "/jam",
          stock: 20,
          perJam: 1,
          perHari: 1,
          perBulan: 0
        },
        {
          id: 4,
          nama: "PlayStation 5",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959761/carter/product/image_82_tmb3ph.png",
          discount: "Rp 7.000/jam",
          price: "Rp 5.500",
          timeType: "/jam",
          stock: 32,
          perJam: 1,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 5,
          nama: "Raket Tennis",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959758/carter/product/image_89_hfbtln.png",
          discount: "Rp 19.500/jam",
          price: "Rp 15.000",
          timeType: "/jam",
          stock: 23,
          perJam: 1,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 6,
          nama: "Palu",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629902732/carter/product/image_72_ywku0y.png",
          discount: "Rp 7.500/jam",
          price: "Rp 4.000",
          timeType: "/jam",
          stock: 59,
          perJam: 1,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 7,
          nama: "Drum Akustik",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959764/carter/product/image_33_clha5g.png",
          discount: "Rp 85.000/hari",
          price: "Rp 52.000",
          timeType: "/hari",
          stock: 12,
          perJam: 0,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 8,
          nama: "Kamera Analog",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959757/carter/product/image_79_czi7xw.png",
          discount: "Rp 68.000/hari",
          price: "Rp 55.000",
          timeType: "/hari",
          stock: 18,
          perJam: 0,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 9,
          nama: "Sempoa Berwarna",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959757/carter/product/image_79_czi7xw.png",
          discount: "Rp 9.500/jam",
          price: "Rp 5.000",
          timeType: "/jam",
          stock: 67,
          perJam: 1,
          perHari: 1,
          perBulan: 1
        }
      ],
      terlaris: [
        {
          id: 1,
          nama: "Kunci Ukuran 3..",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959759/carter/product/image_81_ehskxw.png",
          discount: "Rp 5.500/jam",
          price: "Rp 3.000",
          timeType: "/jam",
          stock: 58,
          perJam: 1,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 2,
          nama: "Piano Klasik",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959762/carter/product/image_84_o5psj8.png",
          discount: "Rp 235.000/jam",
          price: "Rp 325.000",
          timeType: "/hari",
          stock: 12,
          perJam: 0,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 3,
          nama: "Gaun Merah",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959762/carter/product/image_83_z35i3u.png",
          discount: "Rp 180.000/hari",
          price: "Rp 169.000",
          timeType: "/hari",
          stock: 220,
          perJam: 0,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 4,
          nama: "Meja Kayu + Ka..",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959758/carter/product/image_90_f9evvd.png",
          discount: "Rp 7.500/jam",
          price: "Rp 4.500",
          timeType: "/jam",
          stock: 32,
          perJam: 1,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 5,
          nama: "Box Mainan An..",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959758/carter/product/image_78_mqpveh.png",
          discount: "Rp 85.000/hari",
          price: "Rp 20.000",
          timeType: "/hari",
          stock: 120,
          perJam: 0,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 6,
          nama: "Kamera Analog",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629902732/carter/product/image_72_ywku0y.png",
          discount: "Rp 80.000/hari",
          price: "Rp 50.000",
          timeType: "/hari",
          stock: 47,
          perJam: 0,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 7,
          nama: "Kunci Ukuran 3..",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959759/carter/product/image_81_ehskxw.png",
          discount: "Rp 5.500/jam",
          price: "Rp 3.000",
          timeType: "/jam",
          stock: 58,
          perJam: 1,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 8,
          nama: "Piano Klasik",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959762/carter/product/image_84_o5psj8.png",
          discount: "Rp 235.000/jam",
          price: "Rp 325.000",
          timeType: "/hari",
          stock: 12,
          perJam: 0,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 9,
          nama: "Gaun Merah",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959762/carter/product/image_83_z35i3u.png",
          discount: "Rp 180.000/hari",
          price: "Rp 169.000",
          timeType: "/hari",
          stock: 220,
          perJam: 0,
          perHari: 1,
          perBulan: 1
        }
      ]
    };
  },
  components: {
    Global,
    ListProduk,
    carousel
  }
};
</script>

<style scoped>
@media screen and (max-width: 411px) {
  .desktop {
    display: none;
  }

  .container {
    max-width: 470px;
  }

  .mobile {
    display: block !important;
  }
}

/* ====MOBILE===== */
/* FOOTER */
.cpyr p {
  font-size: 12px;
  margin-top: 90px;
  color: #535353;
  margin-bottom: 20px;
}
.footer-mobile ul {
  padding-left: 0;
}

.footer-mobile ul li p {
  font-weight: 600;
  font-size: 16px;

  color: #535353;
}

.footer-mobile h5 {
  line-height: 150.04%;
}
.footer-mobile ul li a,
.footer-mobile h5 {
  margin-top: 8px;
  font-size: 12px;
  margin-bottom: 8px;
  color: #787878;
}
.footer-mobile .sosial-wrapper {
  margin-top: 8px;
}
.footer-mobile .sosial-wrapper img {
  margin-right: 5px;
}
/* QUESTION */
.question-mobile .sewa-barang button {
  background: #335aff;
  border-radius: 5px;
  font-weight: bold;
  font-size: 15px;
  width: 100%;
  padding: 13px 0;
  border: 1px solid #ffffff;
  color: #fff;
  margin-top: 8px;
}

.question-mobile .cari-barang button {
  background: #ffffff;
  border-radius: 5px;
  font-weight: bold;
  font-size: 15px;
  width: 100%;
  padding: 13px 0;
  border: none;
  margin-top: 30px;
  color: #335aff;
}
.question-mobile h1 {
  font-weight: 600;
  font-size: 26px;
  line-height: 35px;
  text-align: center;
  color: white;
}

.question-mobile {
  margin-top: 20px;
  background: #335afe;
  padding: 40px 0;
}

/* PROMO */
.sedang-promo-mobile h2 {
  color: #fff;
}

.sedang-promo-mobile {
  background: #335afe;
  padding: 30px 0;
}

.sedang-promo-mobile a {
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}

/* KATEGORI */
.kategori-mobile .row:nth-child(1) .col-6:nth-child(2) .img-wrapper-mobile p,
.kategori-mobile .row:nth-child(2) .col-6:nth-child(1) .img-wrapper-mobile p {
  top: 40%;
}
.kategori-mobile .img-wrapper-mobile {
  position: relative;
}

.kategori-mobile .img-wrapper-mobile img {
  filter: brightness(0.7);
  width: 100%;
}
.kategori-mobile .img-wrapper-mobile p {
  font-weight: 600;
  font-size: 23px;
  color: #fff;
  position: absolute;
  left: 50%;
  width: 100%;
  top: 45%;
  transform: translateX(-50%);
}

/* NAVIGASI */

.search-wrapper-mobile {
  background: #335aff;
  width: 53px;
  border-radius: 5px;
  height: 43px;
  margin-right: 2px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.search-wrapper-mobile img {
  width: 17px;
  height: 17px;
}

.input-wrapper-mobile input {
  width: 100%;
  /* padding-right: 10px; */
  border: none;
  padding-left: 15px;
  padding-right: 15px;
  outline: none;
  background: #fff;
}

.input-wrapper-mobile {
  margin-top: 15px;
  text-align: center;
  height: 47px;
  padding: 0 0 0 18px;
  background: white;
  border-radius: 5px;
}

.nav-mobile {
  padding: 20px 0 0 0;
}

.header-mobile {
  margin-top: -140px;
  z-index: -99;
  position: relative;
}

/* KATEGORI */
.kategori-mobile .img-wrapper-mobile {
  position: relative;
}

.kategori-mobile .img-wrapper-mobile img {
  filter: brightness(0.7);
  width: 100%;
}

.kategori-mobile .img-wrapper-mobile p {
  font-weight: 600;
  font-size: 23px;
  color: #fff;
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translateX(-50%);
}

/* NAVIGASI */

.search-wrapper-mobile {
  background: #335aff;
  width: 53px;
  border-radius: 5px;
  height: 43px;
  margin-right: 2px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.search-wrapper-mobile img {
  width: 17px;
  height: 17px;
}

.input-wrapper-mobile input {
  width: 100%;
  background: #fff;
}

.input-wrapper-mobile {
  margin-top: 15px;
  text-align: center;
  height: 47px;
  padding: 0 0 0 18px;
  background: white;
  border-radius: 5px;
}

.header-mobile {
  margin-top: -140px;
  z-index: -99;
  position: relative;
}

.cardz:hover {
  transform: translateY(0px);
  transition: 0.3s;
}

.cardz {
  width: 100%;
}
</style>