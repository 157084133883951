<template>
  <div>
    <nav class="navbar nav2 navbar-expand-lg">
      <div class="container">
        <a class="navbar-brand" href="#">
          <img class="img-fluid logo" src="../assets/svg/logo.svg" alt />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a @click.prevent="$emit('inDev')" class="text-hover" href="#">Promo</a>
            </li>
            <li class="nav-item">
              <a @mouseenter="$emit('showNav')" class="text-hover" href="#">Kategori</a>
            </li>
            <li class="nav-item">
              <div class="input-wrapper d-flex align-items-center">
                <img class="img-fluid icon-filter" src="../assets/svg/filter.svg" alt />
                <input placeholder="Cari Barang Anda" type="text" />
                <div class="img-wrapper">
                  <img class="img-fluid" src="../assets/svg/search.svg" alt />
                </div>
              </div>
            </li>
            <li class="nav-item">
              <a @click.prevent="$emit('inDev')" class href="#">
                <img src="../assets/svg/love.svg" alt />
              </a>
            </li>
            <li @click.prevent="$emit('inDev')" class="nav-item">
              <a class href="#">
                <img src="../assets/svg/keranjang.svg" alt />
              </a>
            </li>
            <li class="nav-item">
              <button class="button-primary button-masuk">Masuk</button>
            </li>
            <li class="nav-item">
              <button class="button-primary">Daftar</button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div
      :class="{disDisplayKategoriNav : dataDisplayKategoriNav == 0}"
      class="kategori-nav pt-3 pb-3"
    >
      <div class="container">
        <div class="row">
          <div class="col text-center">
            <a href>Teknologi</a>
          </div>
          <div class="col text-center">
            <a href>Alat Rumah Tangga</a>
          </div>
          <div class="col text-center">
            <a href>Perlengkapan Bayi</a>
          </div>
          <div class="col text-center">
            <a href>Alat Olahraga</a>
          </div>
          <div class="col text-center">
            <a href>Fashion</a>
          </div>
          <div class="col text-center">
            <a href>Alat Musik</a>
          </div>
        </div>
      </div>
    </div>
    <!-- <div
      :class="{disDisplayKategoriNav : dataDisplayKategoriNav == 0}"
      class="kategori-nav pt-3 pb-3"
    >
      <div class="container">
        <div class="row">
          <div class="col text-center">
            <a href>Teknologi</a>
          </div>
          <div class="col text-center">
            <a href>Alat Rumah Tangga</a>
          </div>
          <div class="col text-center">
            <a href>Perlengkapan Bayi</a>
          </div>
          <div class="col text-center">
            <a href>Alat Olahraga</a>
          </div>
          <div class="col text-center">
            <a href>Fashion</a>
          </div>
          <div class="col text-center">
            <a href>Alat Musik</a>
          </div>
        </div>
      </div>
    </div>-->
    <Global />
  </div>
</template>


<script>
import Global from "../screens/Global";

export default {
  name: "navbarMain",
  components: {
    Global
  },
  data: function() {
    return {};
  },
  props: ["dataDisplayKategoriNav"]
};
</script>

<style scoped>
.disDisplayKategoriNav {
  opacity: 0 !important;
  transition: 0.3s;
}
.kategori-nav {
  position: absolute;
  z-index: 9999;
  width: 100%;
  opacity: 1;
  transition: 0.3s;
  background: #f7f7f7;
}

.kategori-nav a {
  font-weight: 600;
  font-size: 15px;
  color: #535353;
}

.kategori-nav a:hover {
  color: #335aff;
}
.button-masuk {
  background: white;
  border: solid #335aff 1px;
  color: #335aff;
  margin-right: 10px;
  transition: 0.3s;
}

.button-masuk:hover {
  background: #335aff;
  border: solid #335aff 1px;
  color: #fff;
  transition: 0.3s;
  margin-right: 10px;
}

.icon-filter {
  cursor: pointer;
  width: 18px;
  height: 16px;
  display: flex;
  justify-content: center;
}

.nav2 .logo {
  margin-top: -10px;
}

.input-wrapper .img-wrapper {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  text-align: center;
  width: 50px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e7ebff;
  transition: 0.3s;
}
.input-wrapper .img-wrapper:hover {
  cursor: pointer;
  background: #3f64ff50;
  transition: 0.3s;
}

.input-wrapper .img-wrapper img {
  width: 19px;
  height: 19px;
}

input {
  outline: none;
  height: 100%;
  width: 100%;
  padding: 0px 20px;
  border: none;
}

.nav2 li:nth-child(1) a {
  margin-right: 30px;
}

.nav2 li:nth-child(2) a {
  margin-right: 30px;
}

.nav2 li a {
  color: #535353;
  font-weight: 600;
  font-size: 15px;

  margin-right: 20px;
}

.navbar-nav {
  align-items: center;
}

.nav2 {
  padding: 18px 0;
  background: white;
  border: solid #e0e0e0 1px;
}

.nav2 li:nth-child(5) a {
  margin-right: 30px;
  margin-left: 15px;
}

.nav2 li:nth-child(4) a {
  margin-left: 30px;
  margin-right: 15px;
}

.input-wrapper {
  padding-left: 20px;
  width: 620px;
  height: 44px;
  border-radius: 5px;
  border: solid #c0c7d1 1px;
}
</style>