<template>
  <div>
    <Global />
    <section @mouseenter.prevent="$emit('hideNav')" class="header">
      <div class="container">
        <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div class="carousel-inner figures">
            <div class="carousel-item active">
              <img src="../assets/img/header-img.png" class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item">
              <img src="../assets/img/header-img-2.png" class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item">
              <img src="../assets/img/header-img-3.png" class="d-block w-100" alt="..." />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Global from "../screens/Global";

export default {
  name: "navbarTop",
  components: {
    Global
  }
};
</script>

<style scoped>
.header .headline {
  height: 300px;
  padding: 90px;
  border-radius: 8px;
}

.header img {
  margin-top: 20px;
  position: relative;
  cursor: pointer;
}
</style>