<template>
  <section class="beranda">
    <section class="desktop">
      <!-- NAVIGASI TOP -->
      <NavbarTop />
      <div class="sticky">
        <NavbarMain
          v-on:inDev="inDev"
          :data-display-kategori-nav="dataDisplayKategoriNav"
          v-on:showNav="showNav"
        />
      </div>
      <!-- END NAVIGASI TOP -->

      <!-- HEADER -->
      <Header v-on:hideNav="hideNav" />
      <!-- END HEADER -->

      <!-- KATEGORI -->
      <Category v-on:inDev="inDev" />
      <!-- END KATEGORI -->

      <div class="container">
        <div class="line"></div>
      </div>

      <!-- PROMO -->
      <section class="promo">
        <div class="container">
          <div class="row">
            <div class="col-lg-3">
              <div class="headline-promo">
                <h5>
                  Sedang
                  <br />Promo
                </h5>
                <div class="countdown d-flex align-items-center">
                  <div class="jam">
                    <p class="mb-0">{{displayHours}}</p>
                  </div>
                  <p class="mb-0 dots">:</p>
                  <div class="menit">
                    <p class="mb-0">{{displayMinutes}}</p>
                  </div>
                  <p class="mb-0 dots">:</p>
                  <div class="detik">
                    <p class="mb-0">{{displaySeconds}}</p>
                  </div>
                </div>
                <a href="#">Lihat Semua</a>
                <div class="d-flex mt-3">
                  <button @click="previousRekomendasi(1)" class="arrowLeftWrapper">
                    <img src="../assets/svg/arrowLeftPromo.svg" alt />
                  </button>
                  <button @click="nextRekomendasi(1)" class="arrowRightWrapper">
                    <img src="../assets/svg/arrowRightPromo.svg" alt />
                  </button>
                </div>
              </div>
            </div>
            <div class="col-lg-9">
              <div class="carousel2-view">
                <transition-group class="carousel2promo justify-content-center" tag="div">
                  <div v-for="myPromo in promo" class="slide2" :key="myPromo.nama">
                    <ListProduk
                      :namaProduk="myPromo.nama"
                      :image="myPromo.image"
                      :discount="myPromo.discount"
                      :price="myPromo.price"
                      :timeType="myPromo.timeType"
                      :stock="myPromo.stock"
                      :perJam="myPromo.perJam"
                      :perHari="myPromo.perHari"
                      :perBulan="myPromo.perJam"
                    />
                  </div>
                </transition-group>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- END PROMO -->

      <div class="container">
        <div class="line"></div>
      </div>

      <!-- REKOMENDASI -->
      <section class="rekomendasi">
        <div class="container">
          <div class="row head-produk align-items-center">
            <div class="col-lg-6">
              <h2 class="mb-0">Rekomendasi</h2>
            </div>
            <div class="col-lg-6">
              <div class="d-flex align-items-center justify-content-end">
                <a class="mb-0" href="#">Lihat Semua</a>
                <div class="d-flex">
                  <button @click="previousRekomendasi(2)" class="arrowLeftWrappers">
                    <img src="../assets/img/arrowLeft.svg" alt />
                  </button>
                  <button @click="nextRekomendasi(2)" class="arrowRightWrappers">
                    <img src="../assets/img/arrowRight.svg" alt />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="carousel2-view">
              <transition-group class="carousel2 justify-content-center" tag="div">
                <div v-for="myRekomendasi in rekomendasi" class="slide2" :key="myRekomendasi.nama">
                  <ListProduk
                    :namaProduk="myRekomendasi.nama"
                    :image="myRekomendasi.image"
                    :discount="myRekomendasi.discount"
                    :price="myRekomendasi.price"
                    :timeType="myRekomendasi.timeType"
                    :stock="myRekomendasi.stock"
                    :perJam="myRekomendasi.perJam"
                    :perHari="myRekomendasi.perHari"
                    :perBulan="myRekomendasi.perJam"
                  />
                </div>
              </transition-group>
            </div>
          </div>
        </div>
      </section>
      <!-- END REKOMENDASI -->

      <div class="container">
        <div class="line"></div>
      </div>

      <!-- BARANG TERLARIS -->
      <section class="rekomendasi">
        <div class="container">
          <div class="row head-produk align-items-center">
            <div class="col-lg-6">
              <h2 class="mb-0">Barang Terlaris</h2>
            </div>
            <div class="col-lg-6">
              <div class="d-flex align-items-center justify-content-end">
                <a class="mb-0" href="#">Lihat Semua</a>
                <div class="d-flex">
                  <button @click="previousRekomendasi(3)" class="arrowLeftWrappers">
                    <img src="../assets/img/arrowLeft.svg" alt />
                  </button>
                  <button @click="nextRekomendasi(3)" class="arrowRightWrappers">
                    <img src="../assets/img/arrowRight.svg" alt />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="carousel2-view">
              <transition-group class="carousel2 justify-content-center" tag="div">
                <div v-for="myTerlaris in terlaris" class="slide2" :key="myTerlaris.id">
                  <ListProduk
                    :namaProduk="myTerlaris.nama"
                    :image="myTerlaris.image"
                    :discount="myTerlaris.discount"
                    :price="myTerlaris.price"
                    :timeType="myTerlaris.timeType"
                    :stock="myTerlaris.stock"
                    :perJam="myTerlaris.perJam"
                    :perHari="myTerlaris.perHari"
                    :perBulan="myTerlaris.perBulan"
                  />
                </div>
              </transition-group>
            </div>
          </div>
        </div>
      </section>

      <!-- END BARANG TERLARIS -->
      <div class="container">
        <div class="line"></div>
      </div>

      <!-- QUESTION -->
      <Question />
      <!-- END QUESTION -->

      <!--  -->
      <div class="container">
        <div class="line"></div>
      </div>

      <!-- FOOTER -->
      <Footer />
      <!-- END FOOTER -->
    </section>

    <BerandaMobile />

    <!--  -->
    <Global />
  </section>
</template>

<script>
import Global from "./Global";
import ListProduk from "../components/ListProduk";
import NavbarTop from "../components/NavbarTop";
import NavbarMain from "../components/NavbarMain";
import Header from "../components/Header";
import Category from "../components/Category";
import Footer from "../components/Footer";
import Question from "../components/Question";
import BerandaMobile from "./mobile/Beranda";

export default {
  name: "Beranda",
  components: {
    Global,
    ListProduk,
    NavbarTop,
    NavbarMain,
    Header,
    Category,
    Footer,
    Question,
    BerandaMobile
  },
  data: function() {
    return {
      dataDisplayKategoriNav: 0,
      displayDays: 0,
      displayHours: 0,
      displayMinutes: 0,
      displaySeconds: 0,
      firstPromo: 0,
      secondPromo: 6,
      firstRekomendasi: 0,
      secondRekomendasi: 7,
      first: 0,
      second: 7,
      nextLabel: "<button>Lala</button>",
      prevLabel: "<button>Lili</button>",
      slides: [
        {
          title: "I am Slide A",
          id: 1
        },
        {
          title: "I am Slide B",
          id: 2
        },
        {
          title: "I am Slide C",
          id: 3
        },
        {
          title: "I am Slide D",
          id: 4
        },
        {
          title: "I am Slide E",
          id: 5
        }
      ],
      promo: [
        {
          id: 1,
          nama: "Gitar Akustik",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629902732/carter/product/image_72_ywku0y.png",
          discount: "Rp 24.000/jam",
          price: "Rp 7.000",
          timeType: "/jam",
          stock: 18,
          perJam: 0,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 2,
          nama: "Kursi Hitam Modern",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959764/carter/product/image_33_clha5g.png",
          discount: "Rp 7.000/jam",
          price: "Rp 7.000",
          timeType: "/jam",
          stock: 30,
          perJam: 1,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 3,
          nama: "Bola Warna",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959765/carter/product/image_85_kydbvc.png",
          discount: "Rp 54.000/jam",
          price: "Rp 3.000",
          timeType: "/jam",
          stock: 120,
          perJam: 1,
          perHari: 0,
          perBulan: 1
        },
        {
          id: 4,
          nama: "Jas Merah + Dasi",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959761/carter/product/image_74_vxbx4t.png",
          discount: "Rp 40.000/jam",
          price: "Rp 25.000",
          timeType: "/jam",
          stock: 22,
          perJam: 1,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 5,
          nama: "Drone Modern",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959761/carter/product/image_71_tfohbx.png",
          discount: "Rp 110.000/jam",
          price: "Rp79.000",
          timeType: "/jam",
          stock: 53,
          perJam: 1,
          perHari: 0,
          perBulan: 1
        },
        {
          id: 6,
          nama: "Gitar Akustik",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629902732/carter/product/image_72_ywku0y.png",
          discount: "Rp 24.000/jam",
          price: "Rp 7.000",
          timeType: "/jam",
          stock: 18,
          perJam: 0,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 7,
          nama: "Kursi Hitam Modern",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959764/carter/product/image_33_clha5g.png",
          discount: "Rp 7.000/jam",
          price: "Rp 7.000",
          timeType: "/jam",
          stock: 30,
          perJam: 1,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 8,
          nama: "Bola Warna",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959765/carter/product/image_85_kydbvc.png",
          discount: "Rp 54.000/jam",
          price: "Rp 3.000",
          timeType: "/jam",
          stock: 120,
          perJam: 1,
          perHari: 0,
          perBulan: 1
        },
        {
          id: 9,
          nama: "Jas Merah + Dasi",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959761/carter/product/image_74_vxbx4t.png",
          discount: "Rp 40.000/jam",
          price: "Rp 25.000",
          timeType: "/jam",
          stock: 22,
          perJam: 1,
          perHari: 1,
          perBulan: 1
        }
      ],
      rekomendasi: [
        {
          id: 1,
          nama: "Kamera Analog",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959757/carter/product/image_79_czi7xw.png",
          discount: "Rp 68.000/hari",
          price: "Rp 55.000",
          timeType: "/hari",
          stock: 18,
          perJam: 0,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 2,
          nama: "Sempoa Berwarna",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959757/carter/product/image_79_czi7xw.png",
          discount: "Rp 9.500/jam",
          price: "Rp 5.000",
          timeType: "/jam",
          stock: 67,
          perJam: 1,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 3,
          nama: "Drone Modern",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959761/carter/product/image_71_tfohbx.png",
          discount: "Rp 210.000/jam",
          price: "Rp 189.000",
          timeType: "/jam",
          stock: 20,
          perJam: 1,
          perHari: 1,
          perBulan: 0
        },
        {
          id: 4,
          nama: "PlayStation 5",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959761/carter/product/image_82_tmb3ph.png",
          discount: "Rp 7.000/jam",
          price: "Rp 5.500",
          timeType: "/jam",
          stock: 32,
          perJam: 1,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 5,
          nama: "Raket Tennis",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959758/carter/product/image_89_hfbtln.png",
          discount: "Rp 19.500/jam",
          price: "Rp 15.000",
          timeType: "/jam",
          stock: 23,
          perJam: 1,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 6,
          nama: "Palu",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629902732/carter/product/image_72_ywku0y.png",
          discount: "Rp 7.500/jam",
          price: "Rp 4.000",
          timeType: "/jam",
          stock: 59,
          perJam: 1,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 7,
          nama: "Drum Akustik",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959764/carter/product/image_33_clha5g.png",
          discount: "Rp 85.000/hari",
          price: "Rp 52.000",
          timeType: "/hari",
          stock: 12,
          perJam: 0,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 8,
          nama: "Kamera Analog",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959757/carter/product/image_79_czi7xw.png",
          discount: "Rp 68.000/hari",
          price: "Rp 55.000",
          timeType: "/hari",
          stock: 18,
          perJam: 0,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 9,
          nama: "Sempoa Berwarna",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959757/carter/product/image_79_czi7xw.png",
          discount: "Rp 9.500/jam",
          price: "Rp 5.000",
          timeType: "/jam",
          stock: 67,
          perJam: 1,
          perHari: 1,
          perBulan: 1
        }
      ],
      terlaris: [
        {
          id: 1,
          nama: "Kunci Ukuran 3..",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959759/carter/product/image_81_ehskxw.png",
          discount: "Rp 5.500/jam",
          price: "Rp 3.000",
          timeType: "/jam",
          stock: 58,
          perJam: 1,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 2,
          nama: "Piano Klasik",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959762/carter/product/image_84_o5psj8.png",
          discount: "Rp 235.000/jam",
          price: "Rp 325.000",
          timeType: "/hari",
          stock: 12,
          perJam: 0,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 3,
          nama: "Gaun Merah",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959762/carter/product/image_83_z35i3u.png",
          discount: "Rp 180.000/hari",
          price: "Rp 169.000",
          timeType: "/hari",
          stock: 220,
          perJam: 0,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 4,
          nama: "Meja Kayu + Ka..",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959758/carter/product/image_90_f9evvd.png",
          discount: "Rp 7.500/jam",
          price: "Rp 4.500",
          timeType: "/jam",
          stock: 32,
          perJam: 1,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 5,
          nama: "Box Mainan An..",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959758/carter/product/image_78_mqpveh.png",
          discount: "Rp 85.000/hari",
          price: "Rp 20.000",
          timeType: "/hari",
          stock: 120,
          perJam: 0,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 6,
          nama: "Kamera Analog",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629902732/carter/product/image_72_ywku0y.png",
          discount: "Rp 80.000/hari",
          price: "Rp 50.000",
          timeType: "/hari",
          stock: 47,
          perJam: 0,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 7,
          nama: "Kunci Ukuran 3..",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959759/carter/product/image_81_ehskxw.png",
          discount: "Rp 5.500/jam",
          price: "Rp 3.000",
          timeType: "/jam",
          stock: 58,
          perJam: 1,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 8,
          nama: "Piano Klasik",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959762/carter/product/image_84_o5psj8.png",
          discount: "Rp 235.000/jam",
          price: "Rp 325.000",
          timeType: "/hari",
          stock: 12,
          perJam: 0,
          perHari: 1,
          perBulan: 1
        },
        {
          id: 9,
          nama: "Gaun Merah",
          image:
            "https://res.cloudinary.com/sarjanalidi/image/upload/v1629959762/carter/product/image_83_z35i3u.png",
          discount: "Rp 180.000/hari",
          price: "Rp 169.000",
          timeType: "/hari",
          stock: 220,
          perJam: 0,
          perHari: 1,
          perBulan: 1
        }
      ]
    };
  },
  mounted() {
    this.showRemaining();
  },
  methods: {
    inDev() {
      alert("IN DEVELOPMENT");
    },
    showNav() {
      this.dataDisplayKategoriNav = 1;
    },
    hideNav() {
      this.dataDisplayKategoriNav = 0;
    },
    showRemaining() {
      const timer = setInterval(() => {
        const now = new Date();
        const end = new Date("09/28/2021 10:1 AM");
        const distance = end.getTime() - now.getTime();

        if (distance < 0) {
          clearInterval(timer);
          return;
        }

        const days = Math.floor(distance / this._days);
        const hours = Math.floor((distance % this._days) / this._hours);
        const minutes = Math.floor((distance % this._hours) / this._minutes);
        const seconds = Math.floor((distance % this._minutes) / this._seconds);
        this.displayMinutes = minutes < 10 ? "0" + minutes : minutes;
        this.displaySeconds = seconds < 10 ? "0" + seconds : seconds;
        this.displayHours = hours < 10 ? "0" + hours : hours;
        this.displayDays = days < 10 ? "0" + days : days;
      }, 1000);
    },
    nextRekomendasi(data) {
      if (data == 1) {
        const first = this.promo.shift();
        this.promo = this.promo.concat(first);
      }

      if (data == 2) {
        const first = this.rekomendasi.shift();
        this.rekomendasi = this.rekomendasi.concat(first);
      }

      if (data == 3) {
        const first = this.terlaris.shift();
        this.terlaris = this.terlaris.concat(first);
      }
    },
    previousRekomendasi(data) {
      if (data == 1) {
        const last = this.promo.pop();
        this.promo = [last].concat(this.promo);
      }

      if (data == 2) {
        const last = this.rekomendasi.pop();
        this.rekomendasi = [last].concat(this.rekomendasi);
      }

      if (data == 3) {
        const last = this.terlaris.pop();
        this.terlaris = [last].concat(this.terlaris);
      }
    }
  },
  computed: {
    _seconds() {
      return 1000;
    },
    _minutes() {
      return this._seconds * 60;
    },
    _hours() {
      return this._minutes * 60;
    },
    _days() {
      return this._hours * 60;
    }
  }
};
</script>

<style scoped>
/* PROMO */
.promo .countdown {
  margin-bottom: 110px;
}

.promo .countdown .dots {
  margin: 0px 10px;
  color: white;
  font-weight: bold;
}

.promo .countdown .dots {
  margin: 0px 10px;
  color: white;
  font-weight: bold;
}

.promo .countdown .jam,
.promo .countdown .menit,
.promo .countdown .detik {
  padding: 5px 9px;
  background: white;
  border-radius: 8px;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  text-align: center;

  color: #335aff;
}

.promo .keranjang-wishlist {
  padding: 8px;
  width: 37px;
  height: 37px;
  border-radius: 5px;
  background: #fff;
}

.promo .keranjang-wislist-wrapper {
  position: absolute;
  right: 18px;
  top: 18px;
  flex-direction: column;
  display: flex;
}

.promo .keranjang-wislist-wrapper img {
  display: flex;
  justify-content: center;
  width: 21px;
  height: 21px;
}

.promo .custom-progress-bar {
  height: 3px !important;
  margin-top: 7px;
  margin-bottom: 3px;
}

.promo .sisa-barang {
  margin-top: 12px;
  font-size: 15px;
  line-height: 20px;
  color: #010223;
}

.promo .cardz {
  background: white;
  /* margin-top: -200px; */
  position: relative;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
}

.promo .harga-diskon {
  margin-top: 10px;
  font-size: 15px;
  text-decoration-line: line-through;
  color: #8d9194;
}

.promo .harga-asli {
  font-weight: bold;
  font-size: 23px;
  color: #335aff;
}

.promo .nama-produk {
  color: #3c3c3c;
  font-size: 20px;
  font-weight: 600;
}

.promo .cardz-body {
  padding: 15px 20px;
}

.promo .kategori-sewa {
  font-size: 15px;
  font-weight: normal;
  color: #8d9194;
}

.promo .kategori-sewa span {
  font-weight: 600;
  color: #3c3c3c;
}

.promo .arrowLeftWrapper {
  margin-right: 15px;
  background: #335aff;
  border: solid 1px #fff;
  width: 45px;
  height: 45px;
  border-radius: 8px;
  line-height: 0px;
  text-align: center;
  transition: 0.3s;
}

.promo .arrowLeftWrapper:hover {
  transition: 0.3s;
  background: #ffffff50;
}

.promo .arrowRightWrapper:hover {
  transition: 0.3s;
  background: #ffffff80;
}

.promo .arrowRightWrapper {
  border: solid 1px #fff;
  width: 45px;
  height: 45px;
  border-radius: 8px;
  line-height: 0px;
  text-align: center;
}

.headline-promo a {
  font-weight: 600;
  font-size: 16px;

  color: #ffffff;
}

.headline-promo h5 {
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 30px;
  color: #ffffff;
}

.headline-promo {
  padding: 70px 40px;
  background-repeat: no-repeat;
  background: url("../assets/img/headline-promo-bg.png");
  height: 458px;
}

.mobile {
  display: none;
}

/*  */
section {
  font-family: "Nunito Sans";
}

a {
  text-decoration: none !important;
}

.text-hover:hover {
  color: #335aff !important;
}

footer ul li a:hover {
  color: #335aff !important;
}

::placeholder {
  font-size: 15px;
  color: #bbbbbb;
}

.lala {
  display: block !important;
  transition: 0.3s;
}

.lalanone {
  display: none;
  transition: 0.3s;
}

.head-produk {
  margin-bottom: 30px;
}

.head-produk a {
  font-weight: 600;
  font-size: 16px;

  color: #3c3c3c;
}

.head-produk h2 {
  font-weight: bold;
  font-size: 30px;

  color: #3c3c3c;
}

.arrowLeftWrappers {
  margin-right: 15px;
  background: white;
  border: solid 1px #335aff;
  width: 45px;
  height: 45px;
  border-radius: 8px;
  line-height: 0px;
  text-align: center;
  margin-left: 60px;
  transition: 0.3s;
}

.arrowLeftWrappers:hover {
  transition: 0.3s;
  background: #335aff80;
}

.arrowRightWrappers:hover {
  transition: 0.3s;
  background: #335aff80;
}

.arrowRightWrappers {
  border: solid 1px #335aff;
  width: 45px;
  height: 45px;
  background: #335aff;
  border-radius: 8px;
  line-height: 0px;
  text-align: center;
  transition: 0.3s;
}

.line {
  height: 1px;
  background: #e0e0e0;
  margin: 40px 0;
  width: 100%;
}

.button-primary {
  background: #335aff;
  padding: 10px 34px;
  color: white;
  border-radius: 5px;
  border: solid 1px #335aff;
  transition: 0.3s;
}

.button-primary:hover {
  background: #335aff50;
  transition: 0.3s;
  padding: 10px 34px;
  color: white;
  border-radius: 5px;
}

.color-white {
  color: white;
}

ul li {
  list-style: none;
}
.carousel2-view {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.carousel2 {
  display: flex;
  justify-content: center;
  /* overflow--x: hidden; */
  /* width: calc(100vw - 240px); */
  /* position: absolute;
  right: 0; */
  align-items: center;
  overflow-x: hidden;
  /* margin-left: -100px; */
  width: 100%;
  min-height: 25em;
}
.carousel2promo {
  display: flex;
  justify-content: center;
  /* overflow--x: hidden; */
  /* width: calc(100vw - 240px); */
  /* position: absolute;
  right: 0; */
  margin-top: 30px;
  align-items: center;
  overflow-x: hidden;
  margin-left: -65px;
  width: 111.5%;
  min-height: 25em;
}
.slide2 {
  /* flex: 0 0 13.5em; */
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in-out;
}
.slide2:first-of-type {
  opacity: 0;
}
.slide2:last-of-type {
  opacity: 0;
}
</style>