<template>
  <div>
    <div class="nav1">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="d-flex">
              <a href="$">
                <p class="mb-0 text-hover">Jadi Penyedia</p>
              </a>
              <a href="#">
                <p class="mb-0 text-hover">Butuh Bantuan?</p>
              </a>
              <a href="#">
                <p class="mb-0 text-hover">Tentang Kami</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Global />
  </div>
</template>

<script>
import Global from "../screens/Global";

export default {
  name: "navbarTop",
  components: {
    Global
  }
};
</script>

<style scoped>
.nav1 {
}

.nav1 {
}

.nav1 {
  align-items: center;
  padding: 13px 0;
  background: #f7f7f7;
}

.nav1 p {
  margin-right: 25px;
  font-weight: 600;
  font-size: 14px;
  color: #535353;
}
</style>