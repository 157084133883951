<template>
  <div>
    <Global />
    <div class="container">
      <section class="question">
        <div class="row align-items-center">
          <div class="col">
            <h2 class="mb-0 color-white">Anda sedang mencari barang atau ingin menyewakan barang?</h2>
          </div>
          <div class="col button-wrapper d-flex justify-content-end align-items-center">
            <button class="button-primary">Cari Barang?</button>
            <button class="button-primary">Sewakan Barang</button>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Global from "../screens/Global";

export default {
  name: "question",
  components: {
    Global
  }
};
</script>

<style scoped>
.question .button-wrapper button:nth-child(1) {
  font-weight: 800;
  font-size: 18px;
  color: #335afe;
  background: white;
  margin: 20px;
  transition: 0.3s;
}

.question .button-wrapper button:nth-child(1):hover {
  transform: translateY(-10px);
  box-shadow: 0px 20px 20px rgba(79, 79, 79, 0.3);
  transition: 0.3s;
}

.question .button-wrapper button:nth-child(2) {
  font-weight: 800;
  font-size: 18px;
  color: #fff;
  background: none;
  border: solid 1px white;
  transition: 0.3s;
}

.question .button-wrapper button:nth-child(2):hover {
  transform: translateY(-10px);
  box-shadow: 0px 20px 20px rgba(79, 79, 79, 0.3);
  transition: 0.3s;
  background: white;
  color: #335afe;
}

.question h2 {
  font-weight: 800;
  font-size: 30px;
  line-height: 40px;
  color: #ffffff;
}

.question {
  background: url("../assets/img/question-bg.png");
  padding: 60px 90px;
  background-repeat: no-repeat;
  height: 202px;
}
</style>