<template>
  <div>
    <Global />
    <div class="section mt-4 kategori">
      <div class="container">
        <div class="row">
          <div class="col-lg-2 col-6">
            <div @click.prevent="$emit('inDev')" class="list-kategori-wrapper">
              <img
                class="img-fluid"
                src="https://res.cloudinary.com/sarjanalidi/image/upload/v1629902329/carter/category/image_63_xbpcdi.png"
                alt="kategori-barang"
              />
              <p class="mb-0">Teknologi</p>
            </div>
          </div>
          <div class="col-lg-2 col-6">
            <div @click.prevent="$emit('inDev')" class="list-kategori-wrapper">
              <img
                class="img-fluid"
                src="https://res.cloudinary.com/sarjanalidi/image/upload/v1629902328/carter/category/image_65_eueze6.png"
                alt="kategori-barang"
              />
              <p class="mb-0">Alat Rumah Tangga</p>
            </div>
          </div>
          <div class="col-lg-2 col-6">
            <div @click.prevent="$emit('inDev')" class="list-kategori-wrapper">
              <img
                class="img-fluid"
                src="https://res.cloudinary.com/sarjanalidi/image/upload/v1629902327/carter/category/image_70_sqhiam.png"
                alt="kategori-barang"
              />
              <p class="mb-0">
                Perlengkapan
                <br />Bayi
              </p>
            </div>
          </div>
          <div class="col-lg-2 col-6">
            <div @click.prevent="$emit('inDev')" class="list-kategori-wrapper">
              <img
                class="img-fluid"
                src="https://res.cloudinary.com/sarjanalidi/image/upload/v1629902461/carter/category/image_68_dxxdcc.png"
                alt="kategori-barang"
              />
              <p class="mb-0">Alat Olahraga</p>
            </div>
          </div>
          <div class="col-lg-2 col-6">
            <div @click.prevent="$emit('inDev')" class="list-kategori-wrapper">
              <img
                class="img-fluid"
                src="https://res.cloudinary.com/sarjanalidi/image/upload/v1629902327/carter/category/image_69_njrlqv.png"
                alt="kategori-barang"
              />
              <p class="mb-0">Fashion</p>
            </div>
          </div>
          <div class="col-lg-2 col-6">
            <div @click.prevent="$emit('inDev')" class="list-kategori-wrapper">
              <img class="img-fluid" src="../assets/img/kategori-img-6.png" alt="kategori-barang" />
              <p class="mb-0">Alat Musik</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Global from "../screens/Global";

export default {
  name: "category",
  components: {
    Global
  }
};
</script>

<style scoped>
/* KATEGORI */

.kategori .col-lg-2:nth-child(2) .list-kategori-wrapper p,
.kategori .col-lg-2:nth-child(3) .list-kategori-wrapper p {
  top: 40%;
}

.kategori img {
  width: 100%;
}

.kategori .list-kategori-wrapper {
  position: relative;
  transition: 0.3s;
}

.kategori .list-kategori-wrapper:hover {
  cursor: pointer;
  transition: 0.3s;
}

.kategori .list-kategori-wrapper:hover img {
  filter: brightness(1);
  transition: 0.3s;
}

.kategori .list-kategori-wrapper:hover p {
  top: 0% !important;
  opacity: 0;
  transition: 0.3s;
}

.kategori .list-kategori-wrapper img {
  filter: brightness(0.7);
  width: 100%;
  transition: 0.3s;
}

.kategori .list-kategori-wrapper p {
  font-weight: 600;
  font-size: 23px;
  color: #fff;
  text-align: center;
  position: absolute;
  left: 50%;
  width: 100%;
  top: 45%;
  transform: translateX(-50%);
  opacity: 1;
  transition: 0.3s;
}
</style>